import React from "react"
import LinkedInIcon from "../../content/assets/icons/linkedin.svg"
import EmailIcon from "../../content/assets/icons/email.svg"
import ForkIcon from "../../content/assets/icons/fork.svg"
import RssIcon from "../../content/assets/icons/rss.svg"
import { ExternalLink, StyledLink } from "./nav"
import styled from "styled-components"

export default function ContactLinks() {
  return (
    <StyledLinksGroup>
      <ExternalLink
        href="https://www.linkedin.com/in/katieleonard/"
        target="_blank"
      >
        <LinkedInIcon />
      </ExternalLink>
      <ExternalLink href="https://github.com/keighty" target="_blank">
        <ForkIcon />
      </ExternalLink>
      <ExternalLink href="mailto:keighty.leonard@gmail.com">
        <EmailIcon />
      </ExternalLink>
      <StyledLink to="/rss.xml">
        <RssIcon />
      </StyledLink>
    </StyledLinksGroup>
  )
}

const StyledLinksGroup = styled.div`
  display: flex;
  justify-content: center;
`