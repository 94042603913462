import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"
import ContactLinks from "../components/contact-links"
import { TagCloud } from "react-tagcloud"
import styled from 'styled-components'


export default function ArchivePage({ data, location }) {
  const siteTitle = data.site.siteMetadata?.title
  const author = data.site.siteMetadata?.author?.name
  const categories = data.allMarkdownRemark.categories

  const tagRenderer = (tag, size, color) => {
    return (
      <StyledTag key={tag.value} size={size} color={color} >
        <Link to={`/archive/${tag.value}`}>{tag.value}</Link>
      </StyledTag>
    )
  }

  const options = {
    luminosity: "bright",
    hue: "blue",
  }

  return (
    <Layout location={location} title={siteTitle} author={author}>
      <SEO title="archive page" />
      <StyledTagcloud
        minSize={6}
        maxSize={15}
        tags={categories}
        renderer={tagRenderer}
        className="simple-cloud"
        colorOptions={options}
      />
      <ContactLinks />
    </Layout>
  )
}

const StyledTagcloud = styled(TagCloud)`
  text-align: center;
  border: 3px solid var(--purple);
  padding: 10px;
  border-radius: 70px;
`

const StyledTag = styled.span`
  font-size: ${props => `${props.size / 4}em`};
  margin: 5px;
  margin: 0px 3px;
  vertical-align: middle;
  display: inline-block;

  a {
    color: ${props => props.color};
    padding: 0.5rem 0;
    text-decoration: none;
    border: solid transparent;
    border-width: 0 0 thick 10px;

    &:hover {
      border: solid var(--purple);
      border-width: 0 0 thick 10px;
      border-color: transparent var(--purple) var(--purple) transparent;
      border-radius: 0 0 20px 0;
      cursor: pointer;
    }
  }
`

export const pageQuery = graphql`
  query CategoryQuery {
    site {
      siteMetadata {
        author {
          name
        }
        title
      }
    }
    allMarkdownRemark {
      categories: group(field: frontmatter___category) {
        value: fieldValue
        count: totalCount
      }
    }
  }
`